import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Button from '../components/Button'
import Layout from '../components/Layout'
import Form from '../components/PerformanceCertificationWaitlistForm'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import Seo from '../components/Seo'
import { ChartBarIcon, CurrencyDollarIcon, GlobeAltIcon, MinusSmIcon, PlusSmIcon, SearchCircleIcon } from '@heroicons/react/outline'
import UnicornCompaniesLogoCloud from '../components/UnicornCompaniesLogoCloud'
import UnicornMark from '../images/unicorn-mark.svg';
import { Link } from 'gatsby'
import { Disclosure } from '@headlessui/react'
import { FadeInStagger, FadeIn } from '../components/FadeIn'

export default function UnicornIndex() {

    const features = [
        {
            name: 'Transparency',
            description:
                "OPEN's deep data and insights power our pricing and index engine, giving you a wider view of the market.",
            href: '#',
            icon: SearchCircleIcon,
        },
        {
            name: 'Designed by you',
            description:
                'Choose from a range of custom thematic indices to suit your investment theme or construct your own from the ground up.',
            href: '#',
            icon: GlobeAltIcon,
        },
        {
            name: 'Simplicity',
            description:
                'We do all of the heavy lifting, so you can focus on what matters most.',
            href: '#',
            icon: ChartBarIcon,
        },
    ]

    const stats = [
        { id: 1, name: 'Unicorns in universe', value: '1300+' },
        { id: 2, name: 'Private market value tracked', value: '$1.1 tn+' },
        { id: 3, name: 'Sectors covered', value: '12' },
    ]

    return (
        <Layout>
            <Seo title="Custom Investment Solutions" description='Build and manage custom portfolios of late stage private companies with OPEN Pegasus leveraging opens proprietary data, sourcing and technology. Streamlining portfolio construction and management.' />
            <div className="relative px-6 lg:px-8 overflow-hidden">
                <div className="absolute transform-gpu overflow-hidden blur-3xl" aria-hidden="true">
                    <div
                        className="left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-accent to-primary opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem] h-inherit animate-background-hero"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-clip blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                    <div
                        className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-accent to-primary opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style={{
                            clipPath:
                                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                        }}
                    />
                </div>
                <FadeIn>
                    <div className="mx-auto max-w-2xl py-32 sm:py-36 lg:py-36">
                        <div className="mb-8 flex justify-center">
                            <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-primary ring-1 ring-primary font-bold">
                                OPEN Pegasus - Custom Indices
                            </div>
                        </div>
                        <div className="text-center">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Our market access and insights. <br /><span className='text-primary'>Your Client's Core Focus.</span>
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Build and manage custom indices of late stage private companies with OPEN Pegasus. We work with you to construct custom indices of pre-IPO companies tailored to your client's specific parameters.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <AnchorLink to="#form" stripHash={true}>
                                    <Button classes='px-5 mt-12'>Request a consultation</Button>
                                </AnchorLink>
                            </div>
                        </div>
                    </div>
                </FadeIn>
            </div>


            <FadeInStagger>
                <div className="bg-gray-50 py-16 sm:py-24">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <FadeIn>
                            <div className="mx-auto max-w-2xl lg:text-center">
                                <h2 className="text-base font-semibold leading-7 text-primary">About</h2>
                                <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                    Your go-to lens for private markets.
                                </p>
                                <p className="mt-6 text-lg leading-8 text-gray-600">
                                    Take advantage of OPEN's price discovery and sourcing network to build and manage a custom index of the companies or sectors where you have the strongest conviction.
                                </p>
                            </div>
                        </FadeIn>
                        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                                {features.map((feature) => (
                                    <FadeIn key={feature.name}>
                                        <div className="flex flex-col">
                                            <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                                                <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                                {feature.name}
                                            </dt>
                                            <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                                                <p className="flex-auto">{feature.description}</p>
                                            </dd>
                                        </div>
                                    </FadeIn>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </FadeInStagger>

            <FadeInStagger>
                <div className="bg-white py-24 sm:py-32">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                            {stats.map((stat) => (
                                <FadeIn key={stat.id}>
                                    <div className="mx-auto flex max-w-xs flex-col gap-y-4">
                                        <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                                        <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                                            {stat.value}
                                        </dd>
                                    </div>
                                </FadeIn>
                            ))}
                        </dl>
                    </div>
                </div>
            </FadeInStagger>


            <FadeInStagger>
                <div className="bg-primary bg-cover bg-[url('../images/raster-bg-alone-2x.png')]" id="form">

                    <div className="mx-auto max-w-7xl px-6 py-20 sm:py-24 lg:flex lg:items-center lg:justify-between lg:px-8">
                        <FadeIn>
                            <h2 className="text-white text-3xl font-bold tracking-tight sm:text-4xl">
                                Find out more?
                                <br />
                                Request an consultation.
                            </h2>
                        </FadeIn>
                        <FadeIn>
                            <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
                                <form name="custom-solutions-consultation-request" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/custom-solutions-enquiry-success" className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6 w-full">
                                    <input type="hidden" name="form-name" value="custom-solutions-consultation-request" />
                                    <input className="hidden" name="bot-field" />
                                    <input type="hidden" id="gclid_field" name="gclid_field" value=""></input>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                                        <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="text" name="name" required placeholder="Name" />
                                    </div>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                                        <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="text" name="company" required placeholder="Company" />
                                    </div>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6 w-full justify-self-end">
                                        <input className="bg-transparent appearance-none border border-gray-100 w-full py-2 px-4 text-white focus:outline-none focus:bg-white/10 focus:border-accent placeholder-white/80 h-12" type="email" name="email" required placeholder="Business Email" />
                                    </div>
                                    <div className="grid grid-cols-1 md:col-span-2 gap-6">
                                        <Button classes='text-white px-3 sm:px-16'>Request consultation</Button>
                                    </div>
                                </form>
                            </div>
                        </FadeIn>
                    </div>
                </div>
            </FadeInStagger>



        </Layout >
    )
}